import { parse, isValid } from 'date-fns';
import { de } from 'date-fns/locale';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';
import { Model, Attribute, HasMany, RelationHandling, BelongsTo } from './decorators';
import { BaseApiModel } from './base';
import { DocumentTypeSection } from './document-type-section.model';
import { DocumentTypeRelation } from './document-type-relation.model';
import { DocumentTypeType, DocumentTypeSectionType, DocumentTypeRelationType } from '@parashift/shared/types';
import { Locale } from './../interfaces/locale.interface';
import { BusinessSector } from './../interfaces/business-sector.interface';
import { NumberConverter } from './converters/number.converter';

const Type = {
  standard: $localize `:@@common.standard:Standard` as 'standard',
  individual: $localize `:@@common.individual:Individual` as 'individual'
};
type Type = (typeof Type)[keyof typeof Type];
export { Type };

const DocumentTypeStatus = {
  draft: $localize `:@@common.draft:Draft` as 'draft',
  test: $localize `:@@common.test:Test/Training` as 'test',
  production: $localize `:@@common.production:Production` as 'production',
  deactivated: $localize `:@@common.deactivated:Deactivated` as 'deactivated',
};
type DocumentTypeStatus = (typeof DocumentTypeStatus)[keyof typeof DocumentTypeStatus];
export { DocumentTypeStatus };

const Visibility = {
  tenant: $localize `:@@common.tenant:Tenant` as 'tenant',
  sub_tenants: $localize `:@@common.sub_tenants:Sub-Tenants` as 'sub_tenants',
  public: $localize `:@@common.public:Public` as 'public',
};
type Visibility = (typeof Visibility)[keyof typeof Visibility];
export { Visibility };

export interface LlmHints {
  speaking_name: string;
  hints: string;
  v: number;
}

export interface DocumentTypePlainModel {
  id: string;
  allow_workflow_changes: boolean;
  business_sectors: BusinessSector[];
  classification_confidence: any;
  classification_rules: string;
  created_at: string;
  description: string;
  extraction_first_level_tenant_id: number;
  extraction_qc_rate: number;
  extraction_qc_tenant_id: number;
  extraction_second_level_tenant_id: number;
  extraction_third_level_tenant_id: number;
  identifier: string;
  inherited_attributes: string[];
  inherited_field_ids: number[];
  inherited_fieldset_ids: number[];
  inherited_relation_ids: number[];
  inherited_section_ids: number[];
  internal: boolean;
  live: boolean;
  live_from: string;
  live_to: string;
  llm_hints: LlmHints;
  locales: Locale[];
  modified_inheritance: boolean;
  parent_id: number;
  qc_external_rate: number;
  qc_external_tenant_id: number;
  qc_internal_rate: number;
  qc_internal_tenant_id: number;
  standard_document_type_id: number;
  standard_document_type_unmodified: boolean;
  status: DocumentTypeStatus;
  tags: string[];
  tenant_id: number;
  title: string;
  updated_at: string;
  visibility: Visibility;

  // Includes / Relations
  parent: DocumentType['plainModel'] | Partial<DocumentType['plainModel']>;
  document_type_relations: (DocumentTypeRelation['plainModel'] | Partial<DocumentTypeRelation['plainModel']>)[];
  document_type_sections: (DocumentTypeSection['plainModel'] | Partial<DocumentTypeSection['plainModel']>)[];
}

@Model({ type: DocumentTypeType })
export class DocumentType extends BaseApiModel<DocumentTypePlainModel> {

  @Attribute()
  allow_workflow_changes: boolean;

  @Attribute()
  business_sectors: BusinessSector[];

  classification_confidence: any;

  @Attribute()
  classification_rules: object;

  @Attribute()
  created_at: string;

  @Attribute()
  description: string;

  @Attribute({ converter: new NumberConverter() })
  extraction_first_level_tenant_id: number;

  @Attribute({ converter: new NumberConverter() })
  extraction_qc_rate: number;

  @Attribute({ converter: new NumberConverter() })
  extraction_qc_tenant_id: number;

  @Attribute({ converter: new NumberConverter() })
  extraction_second_level_tenant_id: number;

  @Attribute({ converter: new NumberConverter() })
  extraction_third_level_tenant_id: number;

  @Attribute()
  identifier: string;

  @Attribute()
  inherited_attributes: string[];

  @Attribute()
  inherited_field_ids: number[];

  @Attribute()
  inherited_fieldset_ids: number[];

  @Attribute()
  inherited_relation_ids: number[];

  @Attribute()
  inherited_section_ids: number[];

  @Attribute()
  internal: boolean;

  @Attribute()
  live: boolean;

  @Attribute()
  live_from: string;

  @Attribute()
  live_to: string;

  @Attribute()
  llm_hints: LlmHints;

  @Attribute()
  locales: Locale[];

  @Attribute()
  modified_inheritance: boolean;

  @Attribute()
  parent_id: number;

  @Attribute({ converter: new NumberConverter() })
  qc_external_rate: number;

  @Attribute({ converter: new NumberConverter() })
  qc_external_tenant_id: number;

  @Attribute({ converter: new NumberConverter() })
  qc_internal_rate: number;

  @Attribute({ converter: new NumberConverter() })
  qc_internal_tenant_id: number;

  @Attribute({ readonly: true })
  standard_document_type_id: number;

  @Attribute({ readonly: true })
  standard_document_type_unmodified: boolean;

  @Attribute()
  status: DocumentTypeStatus;

  @Attribute()
  tags: string[];

  @Attribute()
  tenant_id: number;

  @Attribute()
  title: string;

  @Attribute()
  updated_at: string;

  @Attribute()
  visibility: Visibility;

  // Includes / Relations

  @RelationHandling('destroy')
  @HasMany({ class: DocumentTypeSectionType, sidepostable: true })
  document_type_sections: DocumentTypeSection[];

  @HasMany({ class: DocumentTypeRelationType, sidepostable: true })
  document_type_relations: DocumentTypeRelation[];

  @BelongsTo({ class: DocumentTypeType, sidepostable: false })
  parent: (DocumentType | Partial<DocumentType>);

  get plainModel(): DocumentTypePlainModel {
    const model = this.toHash() as DocumentTypePlainModel;
    model.classification_rules = this.getClassificationRules();
    model.live_from = this.getDate(this.live_from);
    model.live_to = this.getDate(this.live_to);

    return model;
  }

  set plainModel(model) {
    this.customUpdates = {
      classification_rules: setClassificationRules,
      live_from: setDate,
      live_to: setDate,
    };

    this.setPlainModel(model);
  }

  get asTypeaheadOption() {
    return this.title;
  }

  get asClassificationTypeaheadOption() {
    let option = this.title + ' (#' + this.id + ')';
    if (this.classification_confidence > 0) {
      option = option + ' - ' + (this.classification_confidence.toFixed(3) * 100) + '%';
    }
    if (this.status === 'test') {
      option = option + ' (Test)';
    }
    return option;
  }

  getDate(date: string): string {
    if (date) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const formattedDate = formatInTimeZone(new Date(date), timezone, `dd.MM.yyyy HH:mm`);
      return formattedDate;
    } else {
      return null;
    }
  }

  getType(currentTenantId: number): 'standard' | 'individual' {
    if (!this.parent_id && this.tenant_id === currentTenantId) {
      return 'individual';
    } else {
      return 'standard';
    }
  }

  getAllowEditFlag() {
    return this.parent_id ? false : true;
  }

  getClassificationRules() {
    if (!this.classification_rules) {
      return '';
    }
    if (Object.keys(this.classification_rules).length === 0) {
      return '';
    }
    return JSON.stringify(this.classification_rules, null, 2);
  }

  canCurrentTenantEdit(currentTenantId: number) {
    return currentTenantId === this.tenant_id;
  }
}

function isValidDate(date: string): boolean {
  const parsedDate = parse(date, 'dd.MM.yyyy HH:mm', new Date(), { locale: de });
  return isValid(parsedDate);
}

function setDate(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  if (updatedPlainModel[key] && isValidDate(updatedPlainModel[key])) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDate = fromZonedTime(parse(updatedPlainModel[key], 'dd.MM.yyyy HH:mm', new Date()), timezone);
    existingModel[key] = utcDate.toISOString();
  } else {
    existingModel[key] = null;
  }
}

function setClassificationRules(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  if (updatedPlainModel[key] === '') {
    existingModel[key] = {};
  }
  if (updatedPlainModel[key]) {
    existingModel[key] = JSON.parse(updatedPlainModel[key]);
  }
}
